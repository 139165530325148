import React, { Component } from 'react';
import Helmet from 'react-helmet';
//import { Link } from 'react-router-dom';

class Press extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>press | shlumper</title>
                    <meta id="meta-description" name="description" content="News and interviews" />
                </Helmet>

                <div className="main">
                <div className="container exhibition">
                    <h1>Press</h1>
                    <p>2021 – <a href="https://vimeo.com/467842651/6201601d85" rel="noopener noreferrer" target="_blank" title="Link to video interview on Vimeo.com">Link to Artist Profile Video by Mark Pallman</a></p>
                    <p>2013 – <em><a href="http://www.1985artists.com/artist/kevin-heisner"  rel="noopener noreferrer" target="_blank" title="Link to interveiew in 1985 magazine">"Traveling Like Vasco Da Gama"</a></em> – Interview in <em>1985</em></p>
                </div>
            </div>


            </React.Fragment>
        )
    }
}

export default Press;