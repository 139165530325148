import React, { Component } from 'react';
import Helmet from 'react-helmet';

class NFT extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>nft | shlumper</title>
                    <meta id="meta-description" name="description" content="About shlumper" />
                </Helmet>
                <div className="main">
                    <div className="container about">
                        <h1>NFT</h1>
                        <p>"I Bought The Vase" Animations <a href="https://opensea.io/collection/i-bought-the-vase" rel="noopener noreferrer" target="_blank">Opensea on Polygon</a></p>
                        <p>"I Bought The Vase" Stills<a href="https://www.voice.com/masako" rel="noopener noreferrer" target="_blank">Voice on EOSIO</a></p>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default NFT;