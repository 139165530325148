import React from 'react';
import {
  Link
} from "react-router-dom";
const PageHeader = (
  <React.Fragment>
    <header>
      <div className="container">
        <div className="brand">
          <Link to={'/'} className="logo">
            shlumper
          </Link>
          
        </div>
        <nav>
        <Link to={'/'}>Works</Link>
        <Link to={'/exhibition'} >Exhibitions, News & Updates</Link>
        <Link to={'/about'}>About</Link>
        <Link to={'/press'}>Press</Link>
        <a href="/ar/index.html">AR</a>
        <a href="/nft">NFT</a>
        </nav>
        
      </div>
    </header>
  </React.Fragment>
);
export default PageHeader;