// Recent Works Component
import React, { Component } from 'react';


import chicago2020 from '../assets/img/shlumper-chicago-2020.jpg';
import london2019 from '../assets/img/shlumper-london-2019.jpg';
import london2019_2 from '../assets/img/shlumper-london-2019-2.jpg';
import istanbul2019 from '../assets/img/shlumper-istanbul-2019-1.jpg';
import istanbul2019_2 from '../assets/img/shlumper-istanbul-2019-2.jpg';
import composotions_1 from '../assets/img/shlumper-compositions-1.jpg';
import composotions_2 from '../assets/img/shlumper-compositions-2.jpg';
import chicago_wall_2019 from '../assets/img/shlumper-chicago-wall-2019-1.jpg';
import porto_2019 from '../assets/img/shlumper-porto-2019.jpg';
import lego_wall from '../assets/img/shlumper-lego-wall-2019.jpg';
import newyork_2018 from '../assets/img/shlumper-newyork-2018.jpg';
import brain_cutout from '../assets/img/shlumper-brain-2018.jpg';
import dispenser from '../assets/img/shlumper-chicago-dispenser-2017.jpg';
import amsterdam_2019 from '../assets/img/shlumper-amsterdam.jpg';
import bw_wall from '../assets/img/shlumper-bw-wall.jpg';
import chicago_wall from '../assets/img/shlumper-painting-chicago-2019.jpg';
import chicago_exterior from '../assets/img/shlumper-wall-chicago-2019.jpg';
import can from '../assets/img/shlumper-can-2019.jpg';
import mirror_wall from '../assets/img/shlumper-chicago-2019.jpg';
import event_space from '../assets/img/shlumper-chicago-2019-2.jpg';
import gym_wall_1 from '../assets/img/shlumper-chicago-2019-3.jpg';
import gym_wall_2 from '../assets/img/shlumper-chicago-2019-4.jpg';
import hands from '../assets/img/shlumper-hands-2018.jpg';
import face from '../assets/img/shlumper-face-2017.jpg';
import bw from '../assets/img/shlumper-bw.jpg';
import mirrorbox from '../assets/img/shlumper-mirrobox-2018.jpg';
import room_interior from '../assets/img/shlumper-neon-brainpop-2018.jpg';
import bw_drawing from '../assets/img/shlumper-bw-2-2018.jpg';
import canvas_1 from '../assets/img/shlumper-ship-2-2018.jpg';
import ship_wall from '../assets/img/shlumper-ship-wall.jpg';
import ship_wall_2 from '../assets/img/shlumper-ship-profile.jpg';

const ArtItem = (props) => {
    let desc = '';
    let alt  = 'Artwork by shlumper';
    if (props.title) {
        desc = <p>{props.title}</p>;
        alt = props.title;
    }
    return (
        <div>
            <img src={props.img} alt={alt} />
            {desc}
        </div>
    );
}


class Work extends Component {
    render() {
        return (
            <div className="main">
                <div className="container art-display">
                    <ArtItem img={chicago2020} title="Chicago,2020" />
                    <ArtItem img={london2019} title="London, 2019" />
                    <ArtItem img={london2019_2} title="London, 2019" />
                    <ArtItem img={istanbul2019} title="Istanbul, 2019" />
                    <ArtItem img={istanbul2019_2} title="Istanbul, 2019" />
                    <ArtItem img={composotions_1} title="2018" />
                    <ArtItem img={composotions_2} title="2018" />
                    <ArtItem img={chicago_wall_2019} title="Chicago, 2019" />
                    <ArtItem img={porto_2019} title="Porto, 2019" />
                    <ArtItem img={lego_wall} title="2019" />
                    <ArtItem img={newyork_2018} title="New York, 2018" />
                    <ArtItem img={brain_cutout} title="2018" />
                    <ArtItem img={dispenser} title="Chicago, 2017" />
                    <ArtItem img={amsterdam_2019} title="Amsterdam, 2019" />
                    <ArtItem img={bw_wall} />
                    <ArtItem img={chicago_wall} title="Chicago, 2019" />
                    <ArtItem img={chicago_exterior} title="Chicago, 2019" />
                    <ArtItem img={can} title="2019" />
                    <ArtItem img={mirror_wall} title="Chicago, 2019" />
                    <ArtItem img={event_space} title="Chicago, 2019" />
                    <ArtItem img={gym_wall_1} title="2019" />
                    <ArtItem img={gym_wall_2} title="2019" />
                    <ArtItem img={hands} title="2018" />
                    <ArtItem img={face} title="2017" />
                    <ArtItem img={bw} />
                    <ArtItem img={mirrorbox} title="2018" />
                    <ArtItem img={room_interior} title="2018" />
                    <ArtItem img={bw_drawing} title="2018" />
                    <ArtItem img={canvas_1} title="2018" />
                    <ArtItem img={ship_wall} />
                    <ArtItem img={ship_wall_2} />
                </div>
            </div>
        )
    }
}

export default Work;