import React  from 'react';

import {ReactComponent as EmailIcon} from '../assets/svg/email-icon.svg';
import {ReactComponent as InstagramIcon} from '../assets/svg/instagram-icon.svg';
export const PageFooter = (
  <React.Fragment>
    <footer>
      <div className="container">
        <div className="social-media">
          <a href="https://instagram.com/shlumpr" target="_new" alt="Instagram" className="instagram-icon">Instagram<InstagramIcon /></a>
          <a href="mailto:shlumper@shlumper.com" alt="Email Shlumper" className="email-icon">Email<EmailIcon /></a>
        </div>
      </div>
    </footer>
  </React.Fragment>
);
export default PageFooter;