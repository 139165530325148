import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
class Exhibition extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>exhibitions | shlumper</title>
                    <meta id="meta-description" name="description" content="Recent exhibtions" />
                </Helmet>
                <div className="main">
                <div className="container exhibition">
                    <h1>News & Updates</h1>
                    <p>2021 - <a href="https://artsinbushwick.org/bos2021/" rel="noopener noreferrer" target="_blank">BOS2021 - ARTS IN BUSHWICK</a>, <Link to="/files/ShlumperChecklist.pdf" rel="noopener noreferrer" target="_blank" download>Checklist</Link>, Brooklyn, NY​</p>
                    <p>2020 - WORKSHOP 4200, “SIGNS OF LIFE: An Augmented Reality Art Exhibition,” Chicago, IL</p>
                    <p>2020 - OPEN HOUSE CONTEMPORARY, "Intimacies and Influence," Chicago, IL​</p>
                    <p>2019 - GREEN EXCHANGE, "Spiritual Snacks," Chicago, IL</p>
                    <p>2018 - WORKSHOP 4200, "Brain Freeze," Chicago, IL</p>
                    <p>2018 - CONTRA GALLERIES, "SHADOWMAN MEETS THE KIDS," New York, NY</p>
                    <p>2018 - WICKER PARK APARTMENT, "APARTMENT SHOW," Chicago, IL</p>
                    
                </div>
            </div>
            </React.Fragment>
            
        )
    }
}

export default Exhibition;