import React, { Component } from 'react';
import Helmet from 'react-helmet';

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>about | shlumper</title>
                    <meta id="meta-description" name="description" content="About shlumper" />
                </Helmet>
                <div className="main">
                    <div className="container about">
                        <p><strong>Kevin Heisner</strong> is a multi-disciplinary artist. Since graduating from the School of the Art Institute of Chicago as an Art & Technology major in the 80s, he has maintained a long-standing interest in merging art, technology, through his varying creation methods of mixed media paintings or AR activated works connecting exterior spaces to the metaverse. Themes of singularity, human conditions, dimensions, the conscious/subconscious, have been a dominant focus of his practice, even in his street works under his pseudo name of Shlumper. He is based in Chicago and New York, but his works in private collections and public installations are shown globally.</p>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default About;