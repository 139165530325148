import React, { Component } from 'react';
import Work from './Work.js';
import Helmet from "react-helmet";
//import Intro from './Intro';



class Home extends Component {
    render() {


        return (
            <React.Fragment>
                <Helmet>
                    <title>home | shlumper</title>
                    <meta id="meta-description" name="description" content="Artwork by Shlumper" />
                </Helmet>
                <Work />
            </React.Fragment>
        )
    }
}

export default Home;