// App Routes
/**
 * This supplies all the app routes and pages for the website.
 * What happens with project section routes:
 * – For each project type there is a main collection page and individual project pages
 * – Since this is all coming from an API endpoint, it's important that the slug in the URL is sent
 *   to individual project components, hence, for example, the <Development><DevProject></Development> nesting
 * - The important piece of info that goes to each project is the ":slug" part in the URL
 */

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../components/Home';
import Exhibition from '../components/Exhibition';
import Work from '../components/Work';
import About from '../components/About';
import NFT from '../components/NFT';
import Press from '../components/Press';

class AppRoutes extends Component {

    render() {
        return (
            <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/exhibition' component={Exhibition} exact />
                <Route path='/works' component={Work} exact />
                <Route path='/about' component={About} exact />
                <Route path='/press' component={Press} exact />
                <Route path='/nft' component={NFT} exact />
            </Switch>
        )
    }
}
export default AppRoutes