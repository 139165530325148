import React from 'react';
import {BrowserRouter as Router } from 'react-router-dom';
import './assets/stylesheets/shlumpr.scss'
import AppRoutes from './inc/AppRoutes'
import PageHeader from './inc/PageHeader';
import PageFooter from './inc/PageFooter';


function App() {
  return (
    <React.Fragment>
      <Router>
        {PageHeader}
        <AppRoutes />
        {PageFooter}
      </Router>
    </React.Fragment>
  );
}

export default App;
